import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Email } from '../../../assets/js/smtp.js';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  lang$ = this.translocoService.langChanges$;

  form = new FormGroup(
    {
      email: new FormControl('', Validators.required),
      content: new FormControl('', Validators.required),
    },
    {
      updateOn: 'blur',
    }
  );

  constructor(
    private translocoService: TranslocoService,
    private snackBar: MatSnackBar
  ) {}

  sendEmail(): void {
    if (!this.form.valid) {
      return;
    }

    Email.send({
      Host: 'smtp.elasticemail.com',
      Username: 'automotive@consileon.de',
      Password: 'F511F66C3A5B9E22B0F589402D5A985563E8',
      To: 'automotive@consileon.de',
      From: 'automotive@consileon.de',
      Subject: 'E-mobility calculator',
      Body: `
      From ${this.form.value.email}
      <p>${this.form.value.content}</p>
      `,
    }).then((message) => {
      this.snackBar.open(
        this.translocoService.translate('contactForm.snackBar'),
        'X',
        {
          duration: 10000,
        }
      );
      this.form.reset();
    });
  }
}
